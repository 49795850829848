import React, { useContext } from "react";
import { AnswersContext } from "../../AnswersContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { containerVarients } from "../../animations/animations";
import _ from 'lodash';

const InstructionsPopup = () => {
	const {
		quizState,
    } = useContext(AnswersContext);
	const {
		budget,
	} = quizState

	return (
		<div className="great-overlay">
			<motion.div
				className="great-popup instructions-popup"
				variants={containerVarients}
				initial="hidden"
				animate={"visible"}>
				<div className="instructions-top">
                    We saved the best part for last...<br />
                    You're about to compare real wedding photos taken by photographers that meet your criteria.
				</div>
                <div className="instructions-numbers">
                    <div className="instructions-number">1. </div><div className="instructions-item">You'll see sets of 2 photos.</div>
                    <div className="instructions-number">2. </div><div className="instructions-item">Choose the photo you would most like to have for your own wedding.</div>
                    <div className="instructions-number">3. </div><div className="instructions-item">Don't overthink it!</div>
                </div>
                <Link to={`/selection/`}><button className="compare-button">Compare their photos!</button></Link>
			</motion.div>
		</div>
	);
};

export default InstructionsPopup;
