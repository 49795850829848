
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import SharePopup from '.././share-popup.component';
import _ from 'lodash';
import { Mixpanel } from '../../../Mixpanel';
import '../../../styles/results.css'
import verifiedbadge from '../../../styles/verified-badge.svg';
import ShareLogo from '../../../styles/share.svg';
import Instagram from '../../../styles/instagram.svg';
import Website from '../../../styles/website.svg';
import Email from '../../../styles/email.svg';
import ArrowRight from '../../../styles/arrow-right.svg';
import TickCircle from '../../../styles/tick-circle.svg';
import Calendar from '../../../styles/calendar.svg';
import Location from '../../../styles/location.svg';
import closecircleX from "../../../styles/closecircleX"
import Slider from 'react-slick';

// import ResultCard from './results-card.component.js'
// import tempResults from './temp'




function vendorClicked(name, type) {
	Mixpanel.track(`Vendor ${type} Clicked`, { "Name": name})
}

const Tokens = ({ photographer }) => {
	return (
	<div className='result-token-wrapper'>
		{photographer.budgetScore == 100 && <div className='budget-token token'><img src={TickCircle} alt='' />In Your Budget</div>}
		{photographer.calendar_score == 100 && <div className='calendar-confirmed-token token'><img src={Calendar} alt='' />Confirmed Availability</div>}
		{photographer.calendar_score < 100 && photographer.calendar_score > 98 && <div className='calendar-updated-token token'><img src={Calendar} alt='' />Recently Updated Calendar</div>}
	</div>
	)
}

const Weddings = ({ photographer, mobile }) => {
	return (
		<div className={`bottom-left ${mobile ? 'mobile' : ''}`}>
		{photographer.number_of_weddings > 0 &&
		<a onClick = {(e) => vendorClicked(photographer.business_name, 'real weddings')} className='wedding-link' target='_blank' href={`https://wedmatch.com/wedding-photographers/${photographer.business_name?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}`}>
			View {photographer.number_of_weddings} Real Wedding{photographer.number_of_weddings > 1 ? 's' : ''}
		</a>}
		{photographer.weddingExperience > 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} weddings at your venue</div>
		}
		{photographer.weddingExperience == 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} wedding at your venue</div>
		}
	</div>
	)
}

const Stat = ({name, score}) => {
	return (
		<div className='stat-wrapper'>
			<div className='stat-text-wrapper'>
				<div className='stat-name'>{name}</div>
				<div className='stat-score-wrapper'><span className='stat-score'>{score}</span><span className='stat-out-of'>% Match</span></div>
			</div>
			<div className='stat-bar'>
				<div className='stat-bar-background'></div>
				<div className='stat-bar-bar' style={{width: `${score}%`}}></div>
				<div className='stat-bar-circle' style={{left: `calc(${score}% - 5px)`}}></div>
			</div>
		</div>
	)
}

const Quotes = ({ quotes }) => {
	const [ currentQuote, setCurrentQuote ] = useState(0)
	return (
		<div className='quote-box'>
			<div className='flex-between' style={{alignItems: 'center'}}><div className='header-small'>Verified Review Quotes</div><div className='quote-badge'>{quotes[currentQuote].attribute}</div></div>
			<div className='quote-body'>"{quotes[currentQuote].quote}"</div>
			<div className='flex-between'>
				<div className='quote-credit'><span className='quote-author'>{quotes[currentQuote].name}</span><span className='quote-date'> ● {quotes[currentQuote].date.split('-')[1]}/{quotes[currentQuote].date.split('-')[2].split('T')[0]}/{quotes[currentQuote].date.split('-')[0]}</span></div>
				<div className='quote-arrows'>
					<button onClick={() => setCurrentQuote(prev => prev - 1)} disabled={currentQuote === 0} className='quote-arrow'><img alt='next' className='mirror' src={ArrowRight} /></button>
					<button onClick={() => setCurrentQuote(prev => prev + 1)} disabled={currentQuote === quotes.length - 1} className='quote-arrow'><img alt='next' src={ArrowRight} /></button>
				</div>
			</div>
		</div>
	)
}


const toCamelCase = (str) => {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
};

// Function to convert all keys in an object from snake_case to camelCase
const convertKeysToCamelCase = (obj) => {
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = toCamelCase(key); // Convert key to camelCase
      newObj[camelCaseKey] = obj[key]; // Assign value
    }
  }
  return newObj;
};



const PhotographerCards = ({}) => {

	const [first, setFirst] = useState(true)
	const [blurCard, setBlurCard] = useState()
	const [ dataLoaded, setDataLoaded ] = useState(false)
	const [scoredSelections, setScoredSelections ] = useState([])
	const [posted, setPosted ] = useState(false)
	const [ restart, setRestart ] = useState(false)
	const [ locations, setLocations ] = useState([])
	const [ share, setShare ] = useState(false)
	const [ resultId, setResultId ] = useState(null)
	const [ reviewQuotes, setReviewQuotes ] = useState([])
	const [ extraPhotos, setExtraPhotos ] = useState([])
	const [ openEmail, setOpenEmail] = useState(null)
	const [ blurScreen, setBlurScreen] = useState(false)
	const [quizState, setQuizState] = useState({
    attributes: [],
    firstName: '',
    lastName: '',
    budget: null,
    month: '',
    day: '',
    year: '',
    interests: [],
    preference: '',
    email: '',
    optionsTotal: 0,
    moreLocations: [],
    gettingReadyChecked: false,
    gettingReadyImportance: 0,
    ceremonyChecked: false,
    ceremonyImportance: 0,
    receptionChecked: false,
    receptionImportance: 0,
    portraitsChecked: false,
    portraitsImportance: 0,
    canContact: false,
    photoStyles: [],
    weddingLocation: '',
    styleTags: [],
    locationTags: [],
    importantTags: [],
    userOnlyTags: [],
    selectedBudgets: [1, 2],
    hoursSelected: null,
    chosenVendorTypes: [],
    noVenue: false,
    privateVenue: false,
    selections: [],
    venueVendorId: null,
    venueName: '',
  });

	let { result } = useParams();


	useEffect(() => {
		Mixpanel.track('View Results Page');
		axios.get('/api/locations')
		.then(r => setLocations(r.data))
		fetchSavedData()
		fetchAttributesData()
	}, [])


	// const fetchData = async (e) => {

	// 	const token = localStorage.getItem('auth_token')
  //   // Now use the token to verify the user with your existing route
  //   const userResponse = await axios.get('/api/supa-auth-test', {
  //     headers: { Authorization: token },
  //   });

  //   // Store the token in localStorage


	// 	let userId = userResponse.data.data.user.id


  //   if (userId) {
  //     try {
  //       const response = await axios.get('/api/get-quiz-attributes/', {
  //         params: { userId: userId }
  //       })

	// 			const fetchedData = response.data[0]

	// 			const keysToParse = ['photo_styles', 'selections'];

	// 			// Iterate over the keys in fetchedData and parse if necessary
	// 			keysToParse.forEach((key) => {
	// 				if (fetchedData[key] && Array.isArray(fetchedData[key])) {
	// 					fetchedData[key] = fetchedData[key].map((item) => {
	// 						try {
	// 							return JSON.parse(item); // Attempt to parse each item in the array
	// 						} catch (error) {
	// 							console.error(`Failed to parse ${key} for item:`, item);
	// 							return item; // If parsing fails, return the item as is
	// 						}
	// 					});
	// 				}
	// 			});


	// 			const manuallyMappedData = {
	// 				attributes: fetchedData.attributes,
	// 				firstName: fetchedData.first_name,
	// 				lastName: fetchedData.last_name,
	// 				budget: fetchedData.budget,
	// 				month: fetchedData.month,
	// 				day: fetchedData.day,
	// 				year: fetchedData.year,
	// 				interests: fetchedData.interests,
	// 				preference: fetchedData.preference,
	// 				email: fetchedData.email,
	// 				optionsTotal: fetchedData.options_total,
	// 				moreLocations: fetchedData.more_locations,
	// 				gettingReadyChecked: fetchedData.getting_ready_checked,
	// 				gettingReadyImportance: fetchedData.getting_ready_importance,
	// 				ceremonyChecked: fetchedData.ceremony_checked,
	// 				ceremonyImportance: fetchedData.ceremony_importance,
	// 				receptionChecked: fetchedData.reception_checked,
	// 				receptionImportance: fetchedData.reception_importance,
	// 				portraitsChecked: fetchedData.portraits_checked,
	// 				portraitsImportance: fetchedData.portraits_importance,
	// 				canContact: fetchedData.can_contact,
	// 				photoStyles: fetchedData.photo_styles,
	// 				weddingLocation: fetchedData.wedding_location,
					// styleTags: fetchedData.style_tags,
					// locationTags: fetchedData.location_tags,
					// importantTags: fetchedData.important_tags,
					// userOnlyTags: fetchedData.user_only_tags,
	// 				selectedBudgets: fetchedData.selected_budgets,
	// 				hoursSelected: fetchedData.hours_selected,
	// 				chosenVendorTypes: fetchedData.chosen_vendor_types,
	// 				noVenue: fetchedData.no_venue,
	// 				privateVenue: fetchedData.private_venue,
	// 				selections: fetchedData.selections,
	// 				venueVendorId: fetchedData.venue_vendor_id,
	// 				venueName: fetchedData.venue_name
	// 			};

	// 			setQuizState(manuallyMappedData);
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
	// }


	const fetchAttributesData = async (e) => {
		const token = localStorage.getItem('auth_token');

		// Fetch user info from auth
		const userResponse = await axios.get('/api/supa-auth-test', {
			headers: { Authorization: token },
		});

		let userId = userResponse.data.data.user.id;

		if (userId) {
			try {
				// Fetch wedding preferences data
				const weddingResponse = await axios.get('/api/get-quiz-attributes/', {
					params: { userId: userId }
				});

				// Fetch photographer preferences data
				const photographerResponse = await axios.get('/api/get-photographer-attributes/', {
					params: { userId: userId }
				});

				// Combine fetched data
				const fetchedWeddingData = weddingResponse.data[0];
				const fetchedPhotographerData = photographerResponse.data[0];

				const keysToParse = ['photo_styles', 'selections'];

				// Parse array items
				keysToParse.forEach((key) => {
					if (fetchedPhotographerData[key] && Array.isArray(fetchedPhotographerData[key])) {
						fetchedPhotographerData[key] = fetchedPhotographerData[key].map((item) => {
							try {
								return JSON.parse(item); // Attempt to parse each item in the array
							} catch (error) {
								console.error(`Failed to parse ${key} for item:`, item);
								return item; // If parsing fails, return the item as is
							}
						});
					}
				});

				// Combine wedding preferences and photographer preferences data
				const manuallyMappedData = {
					// Wedding preferences data
					firstName: fetchedWeddingData.first_name,
					lastName: fetchedWeddingData.last_name,
					budget: fetchedWeddingData.budget,
					month: fetchedWeddingData.month,
					day: fetchedWeddingData.day,
					year: fetchedWeddingData.year,
					interests: fetchedWeddingData.interests,
					preference: fetchedWeddingData.preference,
					email: fetchedWeddingData.email,
					weddingLocation: fetchedWeddingData.wedding_location,
					canContact: fetchedWeddingData.can_contact,
					moreLocations: fetchedWeddingData.more_locations,
					venueVendorId: fetchedWeddingData.venue_vendor_id,
					venueName: fetchedWeddingData.venue_name,
					chosenVendorTypes: fetchedWeddingData.chosen_vendor_types,
					noVenue: fetchedWeddingData.no_venue,
					privateVenue: fetchedWeddingData.private_venue,
					styleTags: fetchedWeddingData.style_tags,
					locationTags: fetchedWeddingData.location_tags,
					importantTags: fetchedWeddingData.important_tags,
					userOnlyTags: fetchedWeddingData.user_only_tags,

					// Photographer preferences data
					attributes: fetchedPhotographerData.attributes,
					optionsTotal: fetchedPhotographerData.options_total,
					gettingReadyChecked: fetchedPhotographerData.getting_ready_checked,
					gettingReadyImportance: fetchedPhotographerData.getting_ready_importance,
					ceremonyChecked: fetchedPhotographerData.ceremony_checked,
					ceremonyImportance: fetchedPhotographerData.ceremony_importance,
					receptionChecked: fetchedPhotographerData.reception_checked,
					receptionImportance: fetchedPhotographerData.reception_importance,
					portraitsChecked: fetchedPhotographerData.portraits_checked,
					portraitsImportance: fetchedPhotographerData.portraits_importance,
					photoStyles: fetchedPhotographerData.photo_styles,
					styleTags: fetchedPhotographerData.style_tags,
					// selectedBudgets: fetchedPhotographerData.selected_budgets,
					hoursSelected: fetchedPhotographerData.hours_selected,
					selections: fetchedPhotographerData.selections,
				};

				// Update state with combined data
				setQuizState(manuallyMappedData);
			} catch (error) {
				console.log(error);
			}
		}
	};


	const fetchSavedData = async (e) => {
		try {
			const token = localStorage.getItem('auth_token');

			// Fetch user info from auth
			const userResponse = await axios.get('/api/supa-auth-test', {
					headers: { Authorization: token },
			});

			let userId = userResponse.data.data.user.id;

			// Fetch the scored results from the backend
			const response = await axios.get('/api/get-scored-results/', {
					params: { userId: userId },
			});


			if (response.data.scoredResults) {
				// console.log('Scored Results:', response.data.scoredResults);
				setDataLoaded(true)
				setScoredSelections(response.data.scoredResults.slice(0,5));

		}


	} catch (error) {
			console.error('Error fetching scored results:', error);
	}


	}


	const saveResults = async (scoredSelectionsTemp) => {
    const token = localStorage.getItem('auth_token');

    // Fetch user info from auth
    const userResponse = await axios.get('/api/supa-auth-test', {
        headers: { Authorization: token },
    });

    let userId = userResponse.data.data.user.id;

    // Save the scored results to the backend
    const savedResults = await axios.post('/api/save-scored-results', {
        scoredResults: scoredSelectionsTemp.slice(0, 10),
        userId: userId,
    });
};


	const {
		// quizState,
		matchups,
		setValue,
		scoredPhotographers,
	} = useContext(AnswersContext);


	// useEffect(() => {
	// 	Mixpanel.track('View Results Page');
	// 	window.setTimeout(function(){
	// 		setShare(true)
	// 	}, 60000);
	// 	document.title="Results: My top wedding photographers in Richmond, VA based on my personal style, budget, and wedding date."
	// 	if(result && !dataLoaded){
	// 		axios.get('/api/result/' + result)
	// 		.then(r => {
	// 			setDataLoaded(true)
	// 			setScoredSelections(r.data.top_ten.slice(0,5))
		// 		axios.post('/api/review-quotes', { attributes: r.data.attributes, photographerIds: r.data.top_ten.slice(0, 5).map(p => p.photographer.id)})
		// 		.then(r2 => {
		// 			setReviewQuotes(r2.data.quotes)
		// 			setExtraPhotos(r2.data.photos)
		// 		})
		// 	})
		// }
	// 	// localStorage.clear()
	// 	window.pintrk('track', 'signup')
	// 	axios.get('/api/locations')
	// 	.then(r => setLocations(r.data))
	// }, [])


	const {
		attributes,
		firstName,
		lastName,
		budget,
		month,
		day,
		year,
		interests,
		preference,
		email,
		optionsTotal,
		moreLocations,
		gettingReadyChecked,
		gettingReadyImportance,
		ceremonyChecked,
		ceremonyImportance,
		receptionChecked,
		receptionImportance,
		portraitsChecked,
		portraitsImportance,
		canContact,
		photoStyles,
		weddingLocation,
		styleTags,
		locationTags,
		importantTags,
		userOnlyTags,
		selectedBudgets,
		hoursSelected,
		chosenVendorTypes,
		noVenue,
		privateVenue,
		selections,
		venueVendorId,
		venueName
	} = quizState


	function shareSet(){
		setShare(true);
		Mixpanel.track('Share Result Clicked');
	}


	function restartLocation(location){
		Mixpanel.track("Another City Clicked",{
			"Location":location.name
		});
		setValue('weddingLocation', location.id)
		setScoredSelections([])
		setValue('selections', [])
		setTimeout(() => {
			setRestart(true)
		}, 1000)

	}


	function restartBudget(amount){
		Mixpanel.track("Another budget Clicked",{
			"Amount":amount
		});
		setTimeout(() => {
			setRestart(true)
		}, 1000)
	}


	//calculates score and creates the array to be rendered in the results page
	useEffect(() => {
		if(!result && selections.length){
			//removes the keys and just makes it an array of arrays, grouped by photographer



			const selectionsByPhotographer = Object.values(_.groupBy(selections, "photographer_fkey"))
			var scoredSelectionsTemp = [] // holds the array until we set state to scoredSelections
			selectionsByPhotographer.map((photographerArray, index) => {
				const photoScore = photographerArray.length / optionsTotal * .7 * 100
				const totalScore = photoScore
				const photographer = scoredPhotographers.find(p => p.id == photographerArray[0].photographer_fkey)
				scoredSelectionsTemp.push({ photographer, photoScore, totalScore, images: photographerArray})
			})
			scoredSelectionsTemp.sort((a, b) => (a.totalScore > b.totalScore) ? -1 : 1)

			for(var i = 0; i < scoredSelectionsTemp.length; i ++){
				scoredSelectionsTemp[i].rank = i+1
			}
			setValue('usedPhotographers', scoredSelectionsTemp.slice(0, 10).map(p => p.photographer.id))



			axios.post('/api/review-quotes', { attributes: quizState.attributes, photographerIds: scoredSelectionsTemp.slice(0, 5).map(p => p.photographer.id)})
			.then(r2 => {
				setReviewQuotes(r2.data.quotes)
				setExtraPhotos(r2.data.photos)
			})

			if (!dataLoaded) {
				setScoredSelections(scoredSelectionsTemp.slice(0,5))
				var ranked = []

				// if(photoStyles.filter(f => !f.rank).length == 0 && photoStyles.length){
				// 	ranked.push(photoStyles.find(s => s.rank == 1).id)
				// 	ranked.push(photoStyles.find(s => s.rank == 2).id)
				// 	ranked.push(photoStyles.find(s => s.rank == 3).id)
				// 	ranked.push(photoStyles.find(s => s.rank == 4).id)
				// }

				const data = {
					attributes,
					firstName,
					lastName,
					budget,
					month,
					day,
					year,
					interests,
					photographer_preference: preference,
					email,
					selections,
					howImportantGettingReady: (gettingReadyChecked === "true" || gettingReadyChecked === true) ? gettingReadyImportance: 0,
					howImportantCeremony:  (ceremonyChecked === "true" || ceremonyChecked === true) ? ceremonyImportance: 0,
					howImportantReception: (receptionChecked === "true" || receptionChecked === true) ? receptionImportance: 0,
					howImportantPortraits: (portraitsChecked === "true" || portraitsChecked === true) ? portraitsImportance: 0,
					topTen: scoredSelectionsTemp.slice(0,10),
					canContact,
					weddingLocation,
					matchups,
					styleTags,
					locationTags,
					importantTags,
					userOnlyTags,
					photoStyles: ranked,
					selectedBudgets,
					hoursSelected,
					chosenVendorTypes,
					noVenue,
					privateVenue,
					venueVendorId,
					venueName
				};

				if(!posted){
					saveResults(scoredSelectionsTemp)
					axios.post("/api/results", {
						data
					})
					.then((r) => {
						setPosted(true)
						setResultId(r.data.id)
					});
				}
			}
	}
	}, [selections, preference])


	const mostPhotosChosen = scoredSelections?.sort((a, b) => a.images.length > b.images.length ? -1 : 1)[0]?.images.length


	return (
    <div className="mobile:mt-10 w-full">
			 {blurScreen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-40" />
      )}

			<div className="w-full flex flex-col space-y-6 mobile:w-[300px] overflow-hidden mobile:ml-1.5 mobile:mt-5 mobile:hidden">
				{scoredSelections.sort((a, b) => (a.photographer.budgetScore + a.photographer.locationScore + a.photographer.styleScore + (Math.round(a.images.length / mostPhotosChosen * 50 + 50)) + 400)/8 > (b.photographer.budgetScore + b.photographer.locationScore + b.photographer.styleScore + (b.photoScore * 2) + 400)/8 ? -1 : 1).map((s, index) =>
					<div className={`relative w-[930px] flex flex-col pt-3 pb-2.5 px-2 border bg-white rounded-xl`}>
						{openEmail === s.photographer.id && (
							<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-5 pt-2.5 pb-4 border bg-white rounded-2xl shadow-lg z-50'>
								<div className="w-full flex justify-end">
									<button onClick={() => (setOpenEmail(null), setBlurScreen(false))} className="">{closecircleX}</button>
								</div>
								<p className="text-lg font-medium mt-3">{s.photographer.business_name}'s Email: </p>
								<p className="mt-3 text-center">{s.photographer.email}</p>
							</div>
						)}

						<div className="flex">
							<div className='relative w-[400px] ml-0.5'>
								<div className='result-card-left'>
									<div className='image-scroller'>
										{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
											<div key={index}>
												<img alt='selected' key={index} src={i.image_url} />
											</div>
										)}
									</div>
								</div>
									<div className="absolute w-full flex justify-center mt-2.5">
										<Weddings photographer={s.photographer} />
									</div>
							</div>



							<div className='w-full pl-3 pr-1'>
									<div className='w-full flex items-center'>
										<div className="w-[85px] h-[85px] mr-3">
											<img src={s.photographer.profile_photo_url} alt=""/>
										</div>

										<div className="w-full ">
											<div className='w-full'>
												<div className='w-full flex justify-between mobile:flex-col mobile:mt-10'>

													<div className=''>
														<div className='flex text-xl font-medium'>
															<p>{s.photographer.business_name}</p>
															<img alt='' className="ml-1.5" src={verifiedbadge} />
														</div>
														<div className='mt-0.5 text-sm text-[#8C8C8C]'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
													</div>


													<div className='flex'>
														{/* <div className='percent-match-wrapper'>
															<div className='text-lg font-medium'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
															<div className='percentage-bar-background' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 600)/8)}%`}}></div>
															<div className='percentage-bar'><span className="text-xs text-[#8C8C8C]">Match</span></div>
														</div> */}
														<div className='result-left percent-match-wrapper' style={{position: 'relative'}}>
															<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
															<div className='percentage-bar-background'></div>
															<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
															<div style={{marginTop: '6px', fontSize: '10px'}} className=''>Match</div>
														</div>
														<div className='flex items-center justify-center px-[12px] py-2 ml-3 rounded-xl text-xl text-greenblue font-medium bg-[#F3F7F8]'>
															<span>#</span><span>{index + 1}</span>
														</div>
													</div>
												</div>
											</div>
											<div className="h-[30px] flex items-center">
												<Tokens photographer={s.photographer} />
											</div>
										</div>
								</div>

								<div className='result-middle'>
										<div className='stats'>
											<div className='header-small'>Match Stats</div>
												{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
												<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
												<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
												<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
										</div>
										<div className='quotes'>
											{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}

											{!reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <div className='quote-box'><div className='no-reviews'>This photographer doesn't have reviews yet.</div></div>}
										</div>
								</div>
							</div>
						</div>

						<div className={`pl-[38px] pr-3 mt-2.5`}>
							<div className='w-full flex justify-end'>
								{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
									<button className='round-button'><img alt='' src={Instagram} /></button>
								</a>}
								<button onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')}  target='_blank' rel='noopener noreferrer' href={`mailto: ${s.photographer.email}`}>
									<button onClick = {() => (setOpenEmail(s.photographer.id), setBlurScreen(true))} className='social-button'><img alt='' src={Email} />Email</button>
								</button>
								<a className="mt-[1px]" onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
									<button className='social-button inverted'><img alt='' src={Website} />Website</button>
								</a>
							</div>
						</div>
					</div>
				)}
			</div>

			{scoredSelections.sort((a, b) => (a.photographer.budgetScore + a.photographer.locationScore + a.photographer.styleScore + (Math.round(a.images.length / mostPhotosChosen * 50 + 50)) + 400)/8 > (b.photographer.budgetScore + b.photographer.locationScore + b.photographer.styleScore + (b.photoScore * 2) + 400)/8 ? -1 : 1).map((s, index) =>
			<div className='result-card result-card-mobile'>
				<div className='result-top' style={{display: 'flex'}}>
					<div className="w-[80px] h-[80px]">
						<img src={s.photographer.profile_photo_url} alt=""/>
					</div>
					<div style={{width: '100%', paddingTop: '6px', marginLeft: '10px'}}>
						<div className='result-top-right-top'>
							<div className='result-top-right' style={{display: 'flex'}}>
								<div className='result-left'>
									<div className='header' style={{marginBottom: 0, color: 'black'}}>
										{s.photographer.business_name.includes(' ') ? <>{s.photographer.business_name.split(' ').slice(0, Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}<br />{s.photographer.business_name.split(' ').slice(Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}</> : <>{s.photographer.business_name}</> }<img alt='' className="verified-badge" src={verifiedbadge} style={{marginLeft: '10px'}}/></div>
									<div className='subheader'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
								</div>
								<div className='result-right rank'>
									<span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
								</div>

							</div>
						</div>
					</div>
			</div>
			<div className='flex-between' style={{alignItems: 'center', marginBottom: '3px'}}>
			<Tokens photographer={s.photographer} />

			<div style={{position: 'relative'}} className='percent-match-wrapper'>
						<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
						<div className='percentage-bar-background'></div>
						<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
						<div className='subheader' style={{marginTop: '6px', marginBottom: '16px', fontSize: '10px'}}>Match</div>
				</div>
			</div>
			<div className='image-scroller'>
				{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
					<div key={index}>
						<img alt='selected' key={index} src={i.image_url} />
					</div>
				)}
			</div>
				<div className='quotes'>
					{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}
				</div>
				<div className='stats'>
					<div className='header-small'>Match Stats</div>
						{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
						<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
						<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
						<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
				</div>


			<div className="relative text-xs">
				{openEmail === s.photographer.id && (
					<div className='absolute bottom-[130px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-[30px] pt-2.5 pb-4 border bg-white rounded-2xl shadow-lg z-50'>
						<div className="w-full flex justify-end">
							<button onClick={() => (setOpenEmail(null), setBlurScreen(false))} className="">{closecircleX}</button>
						</div>
						<p className="text-lg font-medium mt-3">{s.photographer.business_name}'s Email: </p>
						<p className="mt-3 text-center text-sm">{s.photographer.email}</p>
					</div>
				)}
			</div>
			<div className='social'>
				{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
					<button className='round-button'><img alt='' src={Instagram} /></button>
				</a>}
				<div onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')} target='_blank' rel='noopener noreferrer'>
					<button  onClick={() => (setOpenEmail(s.photographer.id), setBlurScreen(true))} className='social-button'><img alt='' src={Email} />Email</button>
				</div>
				<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
					<button className='social-button inverted'><img alt='' src={Website} />Website</button>
				</a>
			</div>
			<Weddings mobile photographer={s.photographer} />
			</div>
			)}

    </div>
	);
};

export default PhotographerCards;

const LeftArrow = (props) => (
  <button {...props} className="slick-arrow absolute left-[10px] top-1/3 p-2 bg-white opacity-80 rounded-full z-10" aria-label="Previous">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17" y="17" width="17" height="17" rx="8.5" transform="rotate(-180 17 17)" fill="white" fill-opacity="0.75"/>
      <path d="M11.5 4L6.5 8.5L11.5 13" stroke="#0A8A95" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
);


const RightArrow = (props) => (
  <button {...props} className="sslick-arrow absolute right-[10px] top-1/3 p-2 bg-white opacity-80 rounded-full z-10" aria-label="Next">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="17" height="17" rx="8.5" fill="white" fill-opacity="0.75"/>
      <path d="M5.5 4L10.5 8.5L5.5 13" stroke="#0A8A95" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
);


let settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
};