import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import FooterNew from ".././footernew.component";
import { containerVarients } from "../../../animations/animations";
import { Mixpanel } from '../../../Mixpanel';
import DownArrow from '../../../styles/down-arrow.svg';
import ScrollJump from "../../scroll-jump.component";
import CalendarModal from "./calendar"



// const budgetOptions =  [
// 	{
// 		id: 1,
// 		displayMinimum: '$0',
// 		displayMaximum: '$2,000'
// 	},
// 	{
// 		id: 2,
// 		displayMinimum: '$2,001',
// 		displayMaximum: '$3,000'
// 	},
// 	{
// 		id: 3,
// 		displayMinimum: '$3,001',
// 		displayMaximum: '$4,000'
// 	},
// 	{
// 		id: 4,
// 		displayMinimum: '$4,001',
// 		displayMaximum: '$6,000'
// 	},
// 	{
// 		id: 5,
// 		displayMinimum: '$6,000',
// 	},
// ]

const guestOptions = {
	'Under 50': '< 50',
	'51 to 100': '51 - 100',
	'101 to 150': '101 - 150',
	'151 to 200': '151 - 200',
	'201 to 250': '201 - 250',
	'251 to 300': '251 - 300',
	'Over 300': '> 300'
}

const budgetOptions =  [
	{
		id: 1,
		displayMinimum: 'Under $10k',
	},
	{
		id: 2,
		displayMinimum: '$10k',
		displayMaximum: '$15k'
	},
	{
		id: 3,
		displayMinimum: '$15k',
		displayMaximum: '$20k'
	},
	{
		id: 4,
		displayMinimum: '$20k',
		displayMaximum: '$25k'
	},
	{
		id: 5,
		displayMinimum: '$25k',
		displayMaximum: '$30k'
	},
	{
		id: 6,
		displayMinimum: '$30k',
		displayMaximum: '$40k'
	},
	{
		id: 7,
		displayMinimum: '$40k',
		displayMaximum: '$50k'
	},
	{
		id: 8,
		displayMinimum: '$50k',
		displayMaximum: '$75k'
	},
	{
		id: 9,
		displayMinimum: '$75k',
		displayMaximum: '$100k'
	},
	{
		id: 10,
		displayMinimum: '$100k+',
	},
]

const DateNewQuiz = () => {
	document.title="Pick the date | WedMatch"


	const { quizState,	setValue,	toggleBoolean, toggleArraySelection	} = useContext(AnswersContext);
	const [ showMonths, setShowMonths ] = useState(false)
	const [ showDays, setShowDays ] = useState(false)
	const [ showYears, setShowYears ] = useState(false)
	const [ budget, setBudget ] = useState()
	const [ guestCount, setGuestCount ] = useState()
	const [ openCalendar, setOpenCalendar ] = useState(false)
	const [ openBudget, setOpenBudget ] = useState(false)
	const [ openGuest, setOpenGuest ] = useState(false)
	const [ nextStep, setNextStep ] = useState(false)
	const [ phase, setPhase ] = useState('date')
	const budgetDropdownRef = useRef(null);
	const guestDropdownRef = useRef(null);
	const history = useNavigate();


	const handleClickOutside = (event) => {
    if (budgetDropdownRef.current && !budgetDropdownRef.current.contains(event.target)) {
      setOpenBudget(false);
    }
		if (guestDropdownRef.current && !guestDropdownRef.current.contains(event.target)) {
      setOpenGuest(false);
    }
  };


	function trackNoDate(){
		Mixpanel.track('Date Not Decided Clicked');
	}

	const findBudgetOption = (id) => {
		const budgetOption = budgetOptions.find(option => option.id === id);

		if (budgetOption) {
			const { displayMinimum, displayMaximum } = budgetOption;
			return <span className="w-max">{displayMinimum}{displayMaximum ? '-' : ''}{displayMaximum ?? ''}</span>; // Return the values if needed
		} else {
			return null;
		}
	};


	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	useEffect(() => {
		Mixpanel.track('View Date Page');
		if(quizState.month || quizState.day || quizState.year){
			setValue('dateNotDecided', false)
		}
	}, [quizState.month, quizState.day, quizState.year])


	useEffect(() => {
		if(quizState.dateNotDecided){
			setValue('day', "")
			setValue('year', "")
			setValue('month', "")
		}
	}, [quizState.dateNotDecided])


	useEffect(() => {
		if (((quizState.day && quizState.month && quizState.year) || quizState.dateNotDecided) && quizState.guestCount && quizState.wedBudget) {
			setNextStep(true)
		} else {
			setNextStep(false)
		}
	}, [quizState.guestCount, quizState.dateNotDecided, quizState.wedBudget, quizState.day, quizState.month, quizState.year])


	const months = [
		{name: "January", number: "01", days: 31},
		{name: "February", number: "02", days: 29},
		{name: "March", number: "03", days: 31},
		{name: "April", number: "04", days: 30},
		{name: "May", number: "05", days: 31},
		{name: "June", number: "06", days: 30},
		{name: "July", number: "07", days: 31},
		{name: "August", number: "08", days: 31},
		{name: "September", number: "09", days: 30},
		{name: "October", number: "10", days: 31},
		{name: "November", number: "11", days: 30},
		{name: "December", number: "12", days: 31},
	]




	return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
				<NavbarNew />
			</div>

			<div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-[24px] mt-5 rounded-3xl bg-white mobile:w-[90%]">
				{/* Status Bar */}
				<Timeline phase={phase} />

				<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">

					<div className="mb-7 text-center mobile:mb-10">
						<p className="mb-7 text-3xl font-medium mobile:mb-5 mobile:text-lg">Tell us about your perfect wedding day.</p>
						<p className="mobile:text-[13px]">Click or tap to fill in the blanks</p>
					</div>


					<div className={`${quizState.venueName ? 'w-[70%]' : 'w-[50%]'} flex flex-wrap justify-center items-center gap-y-5 text-lg mobile:w-full mobile:mb-6 mobile:text-sm`}>
						<span>I'm getting married on</span>

						<span className={`${quizState.month && quizState.day && quizState.year ? 'text-[#0A8A95]' : 'text-[#A6A6A6]'} px-4 mx-2 italic`} style={{ borderBottom: '1px solid #A6A6A6', cursor: 'pointer' }} onClick={() => setOpenCalendar(true)}>
							{quizState.month && quizState.day && quizState.year ? <div>{`${quizState.month}/${quizState.day}/${quizState.year}` || 'Date'}</div> : <div>Date</div>}
						</span>

						<div>
							{openCalendar && <CalendarModal setOpenCalendar={setOpenCalendar}/>}
						</div>

						<span>at</span>

						<span className="px-4 mx-2 text-[#0A8A95] italic mobile:mt-0" style={{ borderBottom: '1px solid #0A8A95', cursor: 'pointer' }}>
							{quizState.venueName || 'TBD'}
						</span>

						<span>with a wedding budget of</span>

						<span className="relative" ref={budgetDropdownRef}>
							<span
								className={`${findBudgetOption(quizState.wedBudget) !== null ? 'text-[#0A8A95]' : 'text-[#A6A6A6]'} w-max px-3 mx-3 italic`}
								style={{ borderBottom: `1px solid ${findBudgetOption(quizState.wedBudget) !== null ? '#0A8A95' : '#A6A6A6'}`, cursor: 'pointer' }}
								onClick={() => setOpenBudget(!openBudget)}
							>
								{findBudgetOption(quizState.wedBudget) || 'Budget'}
							</span>

							{openBudget && (
								<div className="absolute w-full top-8 h-[200px] flex flex-col px-1 pt-2.5 bg-white rounded-xl text-base shadow overflow-auto z-10 mobile:h-[140px]">
									{budgetOptions.map((range) => (
										<button
											className="hover:bg-gray-100 py-2 rounded-lg mobile:text-sm mobile:px-1"
											onClick={() => {
												quizState.wedBudget = range.id
												setOpenBudget(false);
												setPhase('budget');
												setBudget(`${range.displayMinimum}${range.displayMaximum ? '-' : ''}${range.displayMaximum ?? '+'}`);
											}}
										>
											{range.displayMinimum}{range.displayMaximum ? '-' : ''}{range.displayMaximum ?? ''}
										</button>
									))}
								</div>
							)}
						</span>

						<span>and I plan to have</span>

						<span className="relative" ref={guestDropdownRef}>
							<span
								className={`${quizState.guestCount ? 'text-[#0A8A95]' : 'text-[#A6A6A6]'} px-4 mx-3 italic mobile:px-2 mobile:mx-2`}
								style={{ borderBottom: `1px solid ${quizState.guestCount ? '#0A8A95' : '#A6A6A6'}`, cursor: 'pointer' }}
								onClick={() => setOpenGuest(!openGuest)}
							>
								{quizState.guestCount || 'Guest Count'}
							</span>

							{openGuest && (
								<div className="absolute w-full top-8 h-[200px] flex flex-col px-1 py-2 bg-white rounded-xl shadow overflow-auto mobile:h-[140px]">
									{Object.keys(guestOptions).map((range) => (
										<button className="hover:bg-gray-100 px-2 py-1.5 rounded-lg" onClick={() => (quizState.guestCount = range, setOpenGuest(false), setPhase('guest'))}>
											{range}
										</button>
									))}
								</div>
							)}
						</span>

						<span>guests</span>
					</div>
				</div>

				<FooterNew phase={"date"} complete={nextStep}/>

			</div>
		</div>
	);
};

export default DateNewQuiz;