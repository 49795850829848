import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import FooterNew from ".././footernew.component";
import { containerVarients } from "../../../animations/animations";
import { Mixpanel } from '../../../Mixpanel';
import DownArrow from '../../../styles/down-arrow.svg';
import ScrollJump from "../../scroll-jump.component";
import Calendar from 'react-calendar'
import closecircleX from '../../../styles/closecircleX'
import './calendar.css';

const CalendarModal = ({setOpenCalendar}) => {

  function trackNoDate(){
    Mixpanel.track('Date Not Decided Clicked');
  }

  const { quizState, setValue, toggleBoolean, toggleArraySelection } = useContext(AnswersContext);
  const [value, setValueForCalendar] = useState();
  const [dateSelected, setDateSelected] = useState(false)

  useEffect(() => {
    if (quizState.month && quizState.day && quizState.year) {
      // Create a Date object from quizState values
      const dateFromQuizState = new Date(quizState.year, quizState.month - 1, quizState.day);
      setValueForCalendar(dateFromQuizState); // Set the date for the Calendar
    }
  }, [quizState.month, quizState.day, quizState.year]);

  const closeCalendar = () => {
    if (value) {
      const month = value.getMonth() + 1;
      setValue('month', month)
      const day = value.getDate();
      setValue('day', day)
      const year = value.getFullYear();
      setValue('year', year)
    }
    setOpenCalendar(false)
  }

  useEffect(() => {
    if (value || quizState.dateNotDecided) {
      setDateSelected(true)
    }
  }, [value, quizState.dateNotDecided])



  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur">
      <div className="w-[50%] h-max absolute px-10 py-3 pb-10 bg-white border rounded-3xl z-10 mobile:w-[90%] mobile:px-4">

        <button className="w-full flex justify-end" onClick={() => setOpenCalendar(false)}>{closecircleX}</button>
        <div className="text-xl font-medium mobile:text-lg mobile:px-3">Your wedding date</div>
        <div className="mt-2 text-sm mobile:text-xs mobile:px-3">We'll only match you with vendors that are available on your wedding day.</div>

        <div className="calendar-wrapper">
          <Calendar
          onChange={setValueForCalendar}
          value={value}
          />
        </div>

        <div className="flex justify-between items-center mt-8 mb-3 text-sm mobile:px-3">
          <div className="flex items-center mobile:w-[100px]">
            <label class="checkbox-container" style={{marginRight: '0px', marginTop: '0px'}}>
              <input
                checked={quizState.dateNotDecided}
                type="checkbox"
                name='dateNotDecided'
                onClick={(e) => {trackNoDate(); toggleBoolean(e)}}
              />
              <span class="checkmark email-checkmark" />
            </label>
            <span className="checkbox-label"><span className="mobile:text-xs">We haven't decided yet</span></span>
          </div>

          {dateSelected ?
            <button className="mr-3 bg-greenblue px-4 py-2.5 rounded-full text-base text-white mobile:text-xs mobile:w-max" onClick={() => closeCalendar()}>
              Select Date
            </button>
            :
            <button className="mr-3 bg-gray-300 px-4 py-2.5 rounded-full text-base text-white mobile:text-xs mobile:w-max" onClick={() => closeCalendar()}>
              Select Date
            </button>
          }
        </div>

      </div>
    </div>
  )
}

export default CalendarModal