import React, { useState, useEffect, useContext } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import _ from 'lodash';
import NavbarNew from '.././navbarnew.component';
import Timeline from '.././timeline.component';
import Lock from "../../../styles/lock";
import axios from 'axios';

const supabase = createClient('https://zzhiugmthrvzmeyznffd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp6aGl1Z210aHJ2em1leXpuZmZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjExNDQ4OTIsImV4cCI6MjAzNjcyMDg5Mn0.zuIK4-Uwx9moh225qtJzAzDgEYQfE6AmVuwuM3Sanow');

const SignUp = () => {
  const { quizState, matchups, setValue, scoredPhotographers, handleChangeText } = useContext(AnswersContext);
  const {
    attributes,
    firstName,
    lastName,
    budget,
    month,
    day,
    year,
    interests,
    preference,
    email,
    optionsTotal,
    moreLocations,
    gettingReadyChecked,
    gettingReadyImportance,
    ceremonyChecked,
    ceremonyImportance,
    receptionChecked,
    receptionImportance,
    portraitsChecked,
    portraitsImportance,
    canContact,
    photoStyles,
    weddingLocation,
    styleTags,
    locationTags,
    importantTags,
    userOnlyTags,
    selectedBudgets,
    hoursSelected,
    chosenVendorTypes,
    noVenue,
    privateVenue,
    selections,
    venueVendorId,
    venueName,
    wedBudget,
    photographerPartsDay,
    photographerNumHours,
    photographerMinMax,
    photographerEstimatedBudget,
  } = quizState
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    allowContact: true,
  });
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false)
  const [posted, setPosted] = useState(false)
  const [signUpError, setSignUpError] = useState()
  const history = useNavigate();


  // const scoreSelections = () => {
  //   const selectionsByPhotographer = Object.values(_.groupBy(selections, "photographer_fkey"))
  //   var scoredSelectionsTemp = [] // holds the array until we set state to scoredSelections
  //   selectionsByPhotographer.map((photographerArray, index) => {
  //     const photoScore = photographerArray.length / optionsTotal * .7 * 100
  //     const totalScore = photoScore
  //     const photographer = scoredPhotographers.find(p => p.id == photographerArray[0].photographer_fkey)
  //     scoredSelectionsTemp.push({ photographer, photoScore, totalScore, images: photographerArray})
  //   })
  //   scoredSelectionsTemp.sort((a, b) => (a.totalScore > b.totalScore) ? -1 : 1)

  //   for(var i = 0; i < scoredSelectionsTemp.length; i ++){
  //     scoredSelectionsTemp[i].rank = i+1
  //   }
  //   setValue('usedPhotographers', scoredSelectionsTemp.slice(0, 10).map(p => p.photographer.id))


  //   var ranked = []


  //   const data = {
  //     attributes,
  //     firstName,
  //     lastName,
  //     budget,
  //     month,
  //     day,
  //     year,
  //     interests,
  //     photographer_preference: preference,
  //     email,
  //     selections,
  //     howImportantGettingReady: (gettingReadyChecked === "true" || gettingReadyChecked === true) ? gettingReadyImportance: 0,
  //     howImportantCeremony:  (ceremonyChecked === "true" || ceremonyChecked === true) ? ceremonyImportance: 0,
  //     howImportantReception: (receptionChecked === "true" || receptionChecked === true) ? receptionImportance: 0,
  //     howImportantPortraits: (portraitsChecked === "true" || portraitsChecked === true) ? portraitsImportance: 0,
  //     topTen: scoredSelectionsTemp.slice(0,10),
  //     canContact,
  //     weddingLocation,
  //     matchups,
  //     styleTags,
  //     locationTags,
  //     importantTags,
  //     userOnlyTags,
  //     photoStyles: ranked,
  //     selectedBudgets,
  //     hoursSelected,
  //     chosenVendorTypes,
  //     noVenue,
  //     privateVenue,
  //     venueVendorId,
  //     venueName
  //   };

  //   if(!posted){
  //     axios.post("/api/results", {
  //       data
  //     })
  //     .then((r) => {
  //       setPosted(true)
  //       return (scoredSelectionsTemp.slice(0,10))
  //     });
  //   }
  // }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);


  const handleCombinedChange = (e) => {
    handleInputChange(e);
    handleChangeText(e);
  }


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (formData.password !== formData.confirmPassword) {
      setSignUpError("Passwords don't match")
      setLoading(false)
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
          },
        },
      });

      if (error) {
        setLoading(false)
        setSignUpError(error.message)
      } else {
        localStorage.setItem('auth_token', data.session.access_token);
        const userId = data.user.id

        if (userId) {
          try {
            const weddingResponse = await axios.post('/api/save-quiz-attributes/', {
              quizState: {
                firstName, //whole wed pref
                lastName, //whole wed pref
                wedBudget, //whole wed pref
                month, //whole wed pref
                day, //whole wed pref
                year, //whole wed pref
                interests, // not in?
                preference, // fly
                email, ////whole wed pref
                moreLocations, //whole wed pref
                canContact,//whole wed pref
                weddingLocation, //whole wed pref
                locationTags, //unused ?
                importantTags, //unused ?
                userOnlyTags, //unused ?
                hoursSelected, //whole wed
                chosenVendorTypes, //wholewed
                noVenue, //wholewed
                privateVenue, //wholwed
                venueVendorId, //wholewed
                venueName //wholewed
              },
              userId: userId
            })

            // let scoredSelections = scoreSelections()
            const photographerResponse = await axios.post('/api/save-photographer-preferences/', {
              photoPreferences: {
                attributes,
                optionsTotal, // photog
                gettingReadyChecked, // photog
                gettingReadyImportance, // photog
                ceremonyChecked, // photog
                ceremonyImportance, // photog
                receptionChecked, // photog
                receptionImportance, // photog
                portraitsChecked, // photog
                portraitsImportance, // photog
                photoStyles, // photog
                styleTags, // photog
                selectedBudgets, // photog
                selections, // photog
                photographerPartsDay,
                photographerNumHours,
                photographerMinMax,
                photographerEstimatedBudget,
              },
              userId: userId,
            });

            if (photographerResponse && weddingResponse) {
              setTimeout(() => {
                history('/home');
              }, 1500);
            }

          } catch (error) {
            setLoading(false)
            console.log(error)
          }
        }
      }
    } catch (err) {
      // Catch unexpected errors
      setLoading(false)
      console.error('Unexpected error:', err.message);
    }


  };




  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        {/* Status Bar */}
        <Timeline phase="signup" />

        {/* Create Account Form */}
        <div className="w-full max-w-2xl mx-auto py-10 mobile:mt-5">
          <div className="px-16 mobile:px-4">
            <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">Create Your Account</h2>
            <p className="text-center text-gray-600 mb-8 mobile:text-[15px]">
              Make a Wedmatch account to save and view your matches!
            </p>

            <form onSubmit={handleSubmit} className="space-y-4 mobile:text-sm">
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleCombinedChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />

              <div className="flex items-center">
                <label className="checkbox-container" style={{marginRight: '10px', marginTop: '0px'}}>
                  <input
                    type="checkbox"
                    name="allowContact"
                    checked={formData.allowContact}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark email-checkmark" />
                </label>
                <span className="text-sm text-gray-700 mobile:ml-[8px] mobile:text-[12px]">
                  Allow my matched vendors to contact me with pricing and availability
                </span>
              </div>

              <div className="flex items-center space-x-2 p-3 bg-gray-100 rounded-md">
                <div size={20}> {Lock} </div>
                <p className="text-[11px] text-gray-600">
                  We respect your privacy. We'll never share your information without your permission.
                </p>
              </div>

              {signUpError && <div className="text-red-500">{signUpError}</div>}

              <div className="flex justify-center">
                {loading ?
                <div
                className="bg-greenblue px-[36px] py-3 mt-[20px] rounded-full text-white mobile:text-xs mobile:px-4 flex items-center"
                >
                  <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Creating Account...
                </div>
                :
                <button
                type="submit"
                className={`bg-greenblue px-[36px] py-3 mt-[20px] rounded-full text-white mobile:text-xs mobile:px-4`}
                >
                  Create Account and View Matches
                </button>
                }
              </div>
            </form>



            {/* <p className="text-center text-sm text-greenblue mt-4 hover:underline cursor-pointer mobile:text-xs">
              I already have a Wedmatch account
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

