import React from "react";
import logo from "../../styles/wedmatch-logo.png";
import Twitch from "../../styles/twitch.svg";

const NavbarNew = () => {
    return (
        <div className="navbar-new">
            <div className="navbar-left">
                <img className="navbar-logo" src={logo} />
            </div>
            <div className="navbar-center">
                <img style={{width: '124px'}} className="navbar-logo-mobile" src={logo} />
            </div>
            <div className="navbar-right-mobile">
                <img src={Twitch} style={{width: '24px'}} />
            </div>
        </div>
    );
};

export default NavbarNew;