import React, {useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import axios from 'axios';
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import { Mixpanel } from '../../../Mixpanel';
import ScrollJump from '../../scroll-jump.component';


const VendorTypesNewQuiz = () => {
    document.title="Pick your vendors | WedMatch"

    const { quizState, toggleArraySelection, toggleBoolean, getPhotographers } = useContext(AnswersContext);
    const [finished, setFinished] = useState(false)
    const [vendorTypes, setVendorTypes] = useState([])
    const [nextStep, setNextStep] = useState(false)
    const history = useNavigate();


    useEffect(() => {
        if (quizState.chosenVendorTypes.length || quizState.skipVendors) {
            setNextStep(true)
        } else {
            setNextStep(false)
        }
    }, [quizState.chosenVendorTypes, quizState.skipVendors])


    useEffect(() => {
        Mixpanel.track('View Style Page');
        getPhotographers()
        axios.get('/api/vendor-types')
        .then(r => setVendorTypes(r.data))
    }, [])


    return (
        <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
            <div className="w-full">
                <NavbarNew />
            </div>

            <div className="w-[75%] max-w-[1000px] flex flex-col items-center mt-5 px-3 pt-3 pb-0 rounded-3xl bg-white mobile:w-[90%]">
                {/* Status Bar */}
                <Timeline phase="vendortypes" />

                    <div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">

                        <div className="text-center mobile:mb-5 mb-7">
                            <p className="mb-7 text-3xl font-medium mobile:text-lg mobile:mb-3">Which wedding vendors are you looking to book?</p>
                            {/* <p className="mobile:text-[13px]">Select all that apply even if you're not quite ready to book yet.</p> */}
                        </div>

                        <div className="w-[70%] mobile:w-[95%] mb-[25px]">
                            <div className="flex items-center flex-wrap">
                                <p className="mr-[25px] mobile:text-sm">Available Now: </p>
                                {vendorTypes.map(t =>
                                    (t.name && t.quiz_name === "Photographer" && <button
                                        className={`question-button question-tag-button ${quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.name) ? 'selected' : ''}`}
                                        selected={quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.name)}
                                        onClick={() => toggleArraySelection('chosenVendorTypes', t.name)}
                                        >
                                            <img src={t.icon_url} className="w-[35px] mobile:w-[27px] mobile:mr-[5px]"/>
                                            {t.quiz_name}
                                    </button>)
                                )}
                            </div>
                        </div>

                        <div className="w-[70%] mobile:w-[95%]">
                            <div className="flex">
                                <p className="w-[200px] mr-[24px] mt-3 mobile:w-[500px] mobile:text-sm">Coming Soon: </p>
                                <div className="flex flex-wrap opacity-70">
                                    {vendorTypes.map(t =>
                                    (t.name && t.quiz_name !== "Photographer" && <button
                                            className={`question-button question-tag-button ${quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.name) ? 'selected' : ''}`}
                                            >
                                                <img src={t.icon_url} className="w-[35px] mobile:w-[27px] mobile:mr-[5px]"/>
                                                {t.quiz_name}
                                        </button>)
                                    )}

                                </div>
                            </div>
                        </div>

                        <div>
                            <FooterNew phase={"vendorTypes"} complete={nextStep}/>
                        </div>

                    </div>
            </div>
        </div>




    )
}

export default VendorTypesNewQuiz;