import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import NavbarNew from ".././navbarnew.component";
import PhotographerIcon from "../../../styles/dashboard-icons/photographerIcon"
import FloristIcon from "../../../styles/dashboard-icons/floristIcon"
import VideographerIcon from "../../../styles/dashboard-icons/videographerIcon"
import djIcon from "../../../styles/dashboard-icons/djIcon"
import cakeDessertIcon from "../../../styles/dashboard-icons/cakeDessertIcon"
import hairMakeupIcon from "../../../styles/dashboard-icons/hairMakeupIcon"
import plannerIcon from "../../../styles/dashboard-icons/plannerIcon"
import PhotographerCards from '.././photographer/photographerCards'
import UpArrow from  "../../../styles/dashboard-icons/upArrow"
import DownArrow from  "../../../styles/dashboard-icons/downArrow"
import { Mixpanel } from '../../../Mixpanel';

const Dashboard = () => {

  const [userData, setUserData] = useState()
  const [photogData, setPhotogData] = useState()
  const [firstName, setFirstName] = useState()
  const [vendorMode, setVendorMode] = useState('Photographers')
  const [blurScreen, setBlurScreen] = useState(false)
  const [photographerPreferencesOpen, setPhotographerPreferencesOpen] = useState(false);
  const [weddingDetailsOpen, setWeddingDetailsOpen] = useState(false);
  const history = useNavigate();


  const convertNumberToMonth = (monthNumber) => {
    // Since arrays are zero-indexed, subtract 1 from the monthNumber
    return monthNames[monthNumber - 1];
  };


  const calculateDaysBetween = () => {
    const futureDate = `${convertNumberToMonth(userData.month)} ${userData.day}, ${userData.year}`
    const today = new Date(); // Get today's date
    const future = new Date(futureDate); // Convert the future date string to a Date object

    // Calculate the difference in time (milliseconds)
    const diffInTime = future.getTime() - today.getTime();

    // Convert the time difference from milliseconds to days
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // 1000ms * 3600s * 24hrs

    if (diffInDays === 1) {
      return (<span>{diffInDays} day</span>)
    } else {
      return (<span>{diffInDays} days</span>)
    }
  };


  useEffect(() => {
    if (!localStorage.getItem('auth_token')) {
      history('/login')
    }
		fetchData()
    fetchPhotogData()
	}, [])


  useEffect(() => {
		Mixpanel.track('View Dashboard Page');
  })


  const fetchData = async (e) => {
    const token = localStorage.getItem('auth_token')
    const userResponse = await axios.get('/api/supa-auth-test', {
      headers: { Authorization: token },
    });

		let userId = userResponse?.data?.data?.user?.id
    setFirstName(userResponse?.data?.data?.user?.user_metadata.first_name)


    if (userId) {
      try {
        const response = await axios.get('/api/get-quiz-attributes/', {
          params: { userId: userId }
        })
        if (response.data[0]) {
          setUserData(response.data[0])
        } else {
          // history('/login')
        }
      } catch (error) {
        console.log(error)
      }
    }
	}


  const fetchPhotogData = async (e) => {
    const token = localStorage.getItem('auth_token')
    const userResponse = await axios.get('/api/supa-auth-test', {
      headers: { Authorization: token },
    });

		let userId = userResponse?.data?.data?.user?.id
    setFirstName(userResponse?.data?.data?.user?.user_metadata.first_name)


    if (userId) {
      try {
        const response = await axios.get('/api/get-photographer-attributes/', {
          params: { userId: userId }
        })
        if (response.data[0]) {
          setPhotogData(response.data[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
  }


  const restartQuiz = () => {
    history('/')
  }



  return (
    <div className={`w-full min-h-screen flex flex-col items-center bg-[#F5F7FA] font-poppins z-5`}>
      <div className="w-full">
          <NavbarNew />
      </div>
     {userData && photogData &&
      <div className="w-full min-h-screen flex flex-col items-center bg-[#F5F7FA] font-poppins">

        <div className="w-full max-w-[1200px] mt-12 mb-8">

          <div className="flex mb-[44px] mobile:justify-center mobile:mb-6">
            <h1 className="text-2xl font-medium mobile:text-center mobile:text-xl">{firstName}'s Wedding Vendor Manager</h1>
            {/* <button className="ml-5 px-4 py-2 border bg-white rounded-xl text-black text-xs">
              + Add Vendor Category
            </button> */}
          </div>


          <div className="w-full flex mobile:flex-col mobile:items-center mobile:text-sm">

            {/* Sidebar */}
            <div className="w-[350px] mobile:hidden">
              <div className="border bg-white rounded-xl p-4">
                <h2 className="text-xl font-semibold mb-4 mobile:text-lg">Wedding Details</h2>
                {/* Wedding details placeholder */}
                <div className="space-y-4">
                  <div>
                    <p className="text-sm">Wedding Location</p>
                    <p className="mt-1 font-medium">{locationDic[userData.wedding_location]}</p>
                  </div>

                  <div>
                    <p  className="text-sm">Wedding Venue</p>
                    <p className="mt-1 font-medium">{userData.venue_name || 'TBD'}</p>
                  </div>

                  <div>
                    <p className="text-sm">Wedding Date</p>
                    <p className="mt-1 font-medium">{convertNumberToMonth(userData.month)} {userData.day}, {userData.year} (in {calculateDaysBetween()})</p>
                  </div>

                  <div>
                    <p className="text-sm">Total Wedding Budget</p>
                    <p className="mt-1 font-medium">{`${budgetDict[userData.budget].displayMin}${budgetDict[userData.budget].displayMax ? ' to '  : ''}${budgetDict[userData.budget].displayMax ?? '+'}`}</p>
                  </div>
                </div>
              </div>

              <div className="mt-[24px] border bg-white rounded-xl p-4">
                <h2 className="text-xl font-semibold mb-4 mobile:text-lg">Photographer Preferences</h2>
                {/* Wedding details placeholder */}
                <div className="space-y-4">
                  <div>
                    <p className="text-sm">Photographer Budget</p>
                    <p className="mt-1 font-medium">{photogData.photographer_min_max[0]} to {photogData.photographer_min_max[1]}</p>
                  </div>

                  <div>
                    <p  className="text-sm">Parts of the Day</p>
                    <p className="mt-1 font-medium">
                      {photogData.photographer_parts_day.join(", ")}
                    </p>
                  </div>

                  <div>
                    <p className="text-sm">Number of Hours</p>
                    <p className="mt-1 font-medium">{photogData.photographer_num_hours}</p>
                  </div>

                </div>

              </div>
            </div>

            <div className="hidden w-[360px] mobile:flex mobile:flex-col">

              <div className="border bg-white rounded-xl px-4 py-3">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setWeddingDetailsOpen(!weddingDetailsOpen)}
                >
                  <div className="w-full flex justify-between items-center text-xl font-semibold mobile:text-base">
                    <span>Wedding Details</span>
                    <div>{weddingDetailsOpen ? <span>{UpArrow}</span>: <span>{DownArrow}</span>}</div>
                  </div>
                </div>
                {weddingDetailsOpen && (
                  <div className="space-y-6">
                    <div className="flex justify-between items-center mt-4">
                      <div className="">
                        <p className="text-sm">Wedding Location</p>
                        <p className="mt-1 font-medium">{locationDic[userData.wedding_location]}</p>
                      </div>
                      <div>
                        <p className="text-sm">Wedding Venue</p>
                        <p className="mt-1 font-medium">{userData.venue_name || 'TBD'}</p>
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="pr-3">
                        <p className="text-sm">Wedding Date</p>
                        <p className="mt-1 font-medium">{convertNumberToMonth(userData.month)} {userData.day}, {userData.year} (in {calculateDaysBetween()})</p>
                      </div>
                      <div>
                        <p className="text-sm">Total Wedding Budget</p>
                        <p className="mt-1 font-medium">{`${budgetDict[userData.budget].displayMin}${budgetDict[userData.budget].displayMax ? ' to '  : ''}${budgetDict[userData.budget].displayMax ?? '+'}`}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-[12px] border bg-white rounded-xl px-4 py-3">
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => setPhotographerPreferencesOpen(!photographerPreferencesOpen)}
                >
                  <div className="w-full flex justify-between items-center text-xl font-semibold mobile:text-base">
                    <span>Photographer Preferences</span>
                    <div>{photographerPreferencesOpen ?  <div>{UpArrow}</div>: <div>{DownArrow}</div>}</div>
                  </div>

                </div>

                {photographerPreferencesOpen && (
                  <div className="space-y-6">
                    <div className="flex justify-between items-center mt-4">
                      <div className="">
                        <p className="text-sm">Photographer Budget</p>
                        <p className="mt-1 font-medium">{photogData.photographer_min_max[0]} to {photogData.photographer_min_max[1]}</p>
                      </div>
                      <div>
                        <p className="text-sm">Parts of the Day</p>
                        <p className="mt-1 font-medium">
                          {photogData.photographer_parts_day.join(", ")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-sm">Number of Hours</p>
                      <p className="mt-1 font-medium">{photogData.photographer_num_hours}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className="w-full pl-8 mobile:flex mobile:pl-2 mobile:pr-2">
              {/* <div className="flex space-x-14">
                {userData.chosen_vendor_types.map((vendor, index) => (
                  <button key={index} className="w-[90px] flex flex-col items-center justify-center" onClick={() => setVendorMode(vendor)}>
                    {icons[chosenVendorTypesDic[vendor]]?.svg}
                    <p className="w-max pb-2 mt-2.5 text-sm text-center" style={{ borderBottom: vendorMode === vendor ? "2px solid black" : "none" }}>{vendor}</p>
                  </button>
                ))}
              </div> */}


              <div className="w-full">
                <PhotographerCards setBlurScreen={setBlurScreen}/>
              </div>

            </div>



          </div>

        </div>

      </div>
      }
    </div>
  )

}


export default Dashboard



const icons = {
  "Photographers": {
    "svg": PhotographerIcon
  },
  "Videographers": {
    "svg": VideographerIcon
  },
  "Florists": {
    "svg": FloristIcon
  },
  "DJs": {
    "svg" : djIcon
  },
  "Cake/Dessert": {
    "svg" : cakeDessertIcon
  },
  "Hair/Makeup": {
    "svg" : hairMakeupIcon
  },
  "Planners": {
    "svg" : plannerIcon
  }
}

const chosenVendorTypesDic = {
  "Photographer": "Photographers",
  "Videographer": "Videographers",
  "Florist": "Florists",
  "DJ": "DJs",
  "Bakers": "Cake/Dessert",
  "Hair/Makeup": "Hair/Makeup",
  "Planner/Coordinator": "Planners"
}

const locationDic = {
  "1":	"Richmond, VA",
  "2":	"Charlottesville, VA",
  "3":	"Washington, DC",
  "4":	"Virginia Beach, VA"
}


const budgetDict = {
  1: { displayMin: 'Under $10k', displayMax: null },
  2: { displayMin: '$10k', displayMax: '$15k' },
  3: { displayMin: '$15k', displayMax: '$20k' },
  4: { displayMin: '$20k', displayMax: '$25k' },
  5: { displayMin: '$25k', displayMax: '$30k' },
  6: { displayMin: '$30k', displayMax: '$40k' },
  7: { displayMin: '$40k', displayMax: '$50k' },
  8: { displayMin: '$50k', displayMax: '$75k' },
  9: { displayMin: '$75k', displayMax: '$100k' },
  10: { displayMin: '$100k+', displayMax: null }
};


const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];