import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NewNavbar from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import { AnswersContext } from "../../../AnswersContext";
import "../../../styles/image-sizing.css";
import { motion } from "framer-motion";
import { containerVarients } from "../../../animations/animations";
import { Mixpanel } from '../../../Mixpanel';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CompareImage = styled(motion.img)``;
const CompareContainer = styled.div``;

const SelectionPage = () => {
	const notify = () => toast(<Msg />);
	const Msg = ({ closeToast, toastProps }) => (

		<div>
			<div className="toast-top">
				<div className="toast-top-left">STARTING NOW...</div>
				<div className="toast-top-right">Part {part} of {quizState.timesOfDay}</div>
			</div>
			<div className="toast-body">
		  		{timeOfDay} Photos...
			</div>
		</div>
	  )

	const [ leftRatio, setLeftRatio ] = useState(1);
	const [ rightRatio, setRightRatio ] = useState(1);
	const [ finished, setFinished ] = useState(false);
	const [ animate, setAnimate ] = useState(null);
	const [ comparing1, setComparing1 ] = useState(0)
	const [ comparing2, setComparing2 ] = useState(0)
	const [ timeOfDay, setTimeOfDay ] = useState('')
	const [ part, setPart ] = useState(1)



	const {
		pushToArray,
		matchups,
		setMatchups,
		quizState,
		setValue,
	} = useContext(AnswersContext);



	useEffect(() => {

		setValue('selections', [])
		if(quizState.options1.length > 0){
			setTimeOfDay(quizState.options1[comparing1].time_of_day == "Getting Ready" ? "Wedding Prep" : quizState.options1[comparing1].time_of_day)
		}
		setComparing1(0)
		setComparing2(0)
		setMatchups([])
	}, []);


	useEffect(() => {
		if(part==0){
			 Mixpanel.track("Load Images Step",{
				"Step":0,
				"Name":''
			});
		}

		if(timeOfDay != "" && part > 1){

			notify()
		   Mixpanel.track("Load Images Step",{
				"Step":part,
				"Name":timeOfDay
			});
		}
	}, [timeOfDay])


	const history = useNavigate();

	function determineRatio(left, right) {
		var leftImg = new Image();
		var rightImg = new Image();
		leftImg.onload = function() {
			var height = leftImg.height;
			var width = leftImg.width;
			setLeftRatio(height / width);
		};
		rightImg.onload = function() {
			var height = rightImg.height;
			var width = rightImg.width;
			setRightRatio(height / width);
		};
		leftImg.src = left;
		rightImg.src = right;
	}

	useEffect(() => {
		if(quizState.options1[comparing1] && quizState.options1[comparing1-1] && quizState.options1[comparing1].time_of_day != quizState.options1[comparing1-1].time_of_day){
			setPart(part+1)
			setTimeOfDay(quizState.options1[comparing1].time_of_day == "Getting Ready" ? "Wedding prep" : quizState.options1[comparing1].time_of_day)
		}
	}, [comparing1])


	const chooseImage = (choice, num) => {
		if(num == 1){
			Mixpanel.track("Selection Image Clicked");
			const data = {
				winner: quizState.options1[comparing1].id,
				loser: quizState.options2[comparing2].id
			}
			var arr = [...matchups]
			arr.push(data)
			setMatchups(arr)
		}
		else if(num == 2){
			Mixpanel.track("Selection Image Clicked");
			const data = {
				winner: quizState.options2[comparing2].id,
				loser: quizState.options1[comparing1].id
			}
			var arr = [...matchups]
			arr.push(data)
			setMatchups(arr)
		}
		setTimeout(function() {
			setAnimate(null);
			pushToArray('selections', choice)
			if (quizState.options1[comparing1 + 1] && quizState.options2[comparing2 + 1]) {
				localStorage.comparing1 = comparing1 + 1
				localStorage.comparing2 = comparing2 + 1
				setComparing1((prev) => prev + 1);
				setComparing2((prev) => prev + 1);
			} else if (quizState.options1.length > 0) {
				setDelay();
			}
		}, 250);
	};


	const OptionsDisplay = () => {
		if (quizState.options1.length > 0 && quizState.options2.length > 0 && !finished) {
			determineRatio(quizState.options1[comparing1]?.image_url, quizState.options2[comparing2]?.image_url);
			var leftClass = "";
			var rightClass = "";
			var containerClass = "";
			if (leftRatio > 1) {
				leftClass = "portrait";
				if (rightRatio > 1) {
					rightClass = "portrait";
					containerClass = "portrait-portrait";
				} else if (rightRatio < 1) {
					rightClass = "landscape";
					containerClass = "portrait-landscape";
				} else {
					rightClass = "square";
					containerClass = "portrait-square";
				}
			} else if (leftRatio < 1) {
				leftClass = "landscape";
				if (rightRatio > 1) {
					rightClass = "portrait";
					containerClass = "landscape-portrait";
				} else if (rightRatio < 1) {
					rightClass = "landscape";
					containerClass = "landscape-landscape";
				} else {
					rightClass = "square";
					containerClass = "landscape-square";
				}
			} else {
				leftClass = "square";
				if (rightRatio > 1) {
					rightClass = "portrait";
					containerClass = "square-portrait";
				} else if (rightRatio < 1) {
					rightClass = "landscape";
					containerClass = "square-landscape";
				} else {
					rightClass = "square";
					containerClass = "square-square";
				}
			}
			containerClass += " compare-container";
			if (animate === 0) {
				leftClass += " compare-image left animate-0";
				rightClass += " compare-image right disappear";
			} else if (animate === 1) {
				leftClass += " compare-image left disappear";
				rightClass += " compare-image right animate-1";
			} else {
				leftClass += " compare-image left";
				rightClass += " compare-image right";
			}

			return (
				<CompareContainer className={containerClass}>
					<CompareImage
						className={leftClass}
						onClick={() => {
							setAnimate(0);
							chooseImage(quizState.options1[comparing1], 1);
						}}
						src={quizState.options1[comparing1]?.image_url}
					/>
					<CompareImage
						className={rightClass}
						onClick={() => {
							setAnimate(1);
							chooseImage(quizState.options2[comparing2], 2);
						}}
						src={quizState.options2[comparing2]?.image_url}
					/>
				</CompareContainer>
			);
		} else if (quizState.options1.length == 0 || quizState.options1.length == 0) {
			return (
				<div style={{ textAlign: "center", fontSize: "2rem", marginTop: "30px", color: "#6a6868" }}>
					Loading...
				</div>
			);
		} else{
			return(
				<div>Something went wrong</div>
			)
		}
	};

	// //ANIMATION WORK
	const [ addLeaveAnimation, setAddLeaveAnimation ] = useState(false);


	const setDelay = () => {
		setAddLeaveAnimation(true);
		window.setTimeout(() => {
			if(quizState.email){
				history("/results")
			} else{
				history("/signup");
			}
		}, 500);
	};

	return (

		<div>
			<NewNavbar />
			<div className="w-full flex justify-center">
				<div className="flex justify-center w-[1000px]">
						<Timeline phase="selectionquiz"/>
				</div>
			</div>

			<div className="mobile:mt-10">
				<div className="photo-progress photo-progress-mobile">
					<div className="photo-progress-background">	</div>
					<div className="photo-progress-complete"  style={{width: (quizState.selections.length/quizState.options1.length*100 + 2) + "%"}}></div>
				</div>
			</div>

			<div
				variants={containerVarients}
				initial="hidden"
				animate={addLeaveAnimation ? "leave" : "visible"}
				className="images-page">
				<div
					style={{
						fontSize: "14px",
						textAlign: "center",
						color: "#1B2D49",
						margin: "0 auto",
						paddingTop: "10px"
					}}>
					<b>Part {part} of {quizState.timesOfDay}:</b> {timeOfDay}

				<div className="photo-progress photo-progress-desktop">
					<div className="photo-progress-background">	</div>
					<div className="photo-progress-complete"  style={{width: (quizState.selections.length/quizState.options1.length*100 + 1) + "%"}}></div>
				</div>
				</div>
				<OptionsDisplay />
				<ToastContainer
				autoClose={3500}
				transition={Zoom}
				position="bottom-center"
				/>
			</div>
		</div>
	);
};

export default SelectionPage;
