import React from "react";
import logo from "../../styles/wedmatch-logo.png";
import Twitch from "../../styles/twitch.svg";
import { useNavigate } from "react-router-dom";

const NavbarNew = () => {

    const history = useNavigate();

    return (
        <div className="navbar-new">
            <div className="navbar-left">
                <img onClick={() => history("/home")} className="navbar-logo cursor-pointer" src={logo} />
            </div>
            <div className="navbar-center">
                <img style={{width: '124px'}} className="navbar-logo-mobile" src={logo} />
            </div>
            <div className="navbar-right">
                <button onClick={() => history("/account")} className="navbar-feedback-button">Account</button>
            </div>
            <div className="navbar-right-mobile">
                <img src={Twitch} style={{width: '24px'}} />
            </div>
        </div>
    );
};

export default NavbarNew;